import { createSlice } from "@reduxjs/toolkit";
import { omitDepartments, omitProfiles } from "../../utils/data";

const initialSchedulesState = {
  listLoading: false,
  actionsLoading: false,
  totalCount: 0,
  entities: [],
  profiles: [],
  departments: [],
  scheduleForEdit: undefined,
  lastError: null
};
export const callTypes = {
  list: "list",
  action: "action"
};

export const schedulesSlice = createSlice({
  name: "schedules",
  initialState: initialSchedulesState,
  reducers: {
    catchError: (state, action) => {
      state.error = `${action.payload.error}`;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },
    startCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else {
        state.actionsLoading = true;
      }
    },
    // getScheduleById
    scheduleFetched: (state, action) => {
      state.actionsLoading = false;
      state.scheduleForEdit = action.payload.data;
      state.error = null;
    },
    // findSchedules
    schedulesFetched: (state, action) => {
      const { totalCount, entities } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.entities = entities;
      state.totalCount = totalCount;
    },
    // findSchedules
    profilesFetched: (state, action) => {
      state.listLoading = false;
      state.error = null;
      state.profiles = action.payload.filter(
        ele => !omitProfiles.includes(ele.id)
      );
    },
    // findSchedules
    departmentsFetched: (state, action) => {
      state.listLoading = false;
      state.error = null;
      state.departments = action.payload.filter(ele =>
        omitDepartments.includes(ele.id)
      );
    },
    // createSchedule
    scheduleCreated: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
      state.entities.push(action.payload.data);
    },
    // updateSchedule
    scheduleUpdated: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.map(entity => {
        if (entity.id === action.payload.schedule.id) {
          return action.payload.schedule;
        }
        return entity;
      });
    },
    // deleteSchedule
    scheduleDeleted: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.filter(el => el.id !== action.payload.id);
    },
    // deleteSchedules
    schedulesDeleted: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.filter(
        el => !action.payload.ids.includes(el.id)
      );
    },
    // schedulesUpdateState
    schedulesStatusUpdated: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
      const { ids, status } = action.payload;
      state.entities = state.entities.map(entity => {
        if (ids.findIndex(id => id === entity.id) > -1) {
          entity.status = status;
        }
        return entity;
      });
    },
    // schedulesUpdateState
    attended: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
      const { id, data } = action.payload;
      state.entities = state.entities.map(entity => {
        if (Number(id) === entity.id) {
          entity = {
            ...entity,
            link: data.link,
            link_details: data.link_details,
            message: data.message,
            status: true
          };
        }
        return entity;
      });
    },
    signedOut: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
      const { id, data } = action.payload;
      state.entities = state.entities.map(entity => {
        if (Number(id) === entity.id) {
          entity = {
            ...entity,
            attendance: {
              ...entity.attendance,
              sign_out_time: data.sign_out_time
            }
          };
        }
        return entity;
      });
    },
    // scheduleUpdateState
    scheduleStatusUpdated: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
      const { status } = action.payload;
      state.scheduleForEdit.status = status;
    }
  }
});
