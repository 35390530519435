export const omitProfiles = [
  42,
  46,
  12,
  48,
  1,
  13,
  15,
  55,
  11,
  35,
  72,
  70,
  17,
  38,
  7,
  71,
  75,
  9,
  43,
  10,
  6,
  56,
  33,
  39,
  18,
  14,
  34,
  45,
  44,
  19,
  73,
  74
];

export const omitDepartments = [
  16,
  42,
  52,
  66,
  67,
  68,
  167,
  168,
  169,
  170,
  171,
  172,
  173,
  165,
  176,
  186,
  187,
  188,
  189,
  190,
  191,
  215,
  217
];
